.products__bkg {
  background: linear-gradient(
    0deg,
    rgba(6, 20, 32, 1) 0%,
    rgba(9, 28, 45, 1) 24%,
    rgba(13, 41, 68, 1) 55%
  );
}
.product-category__wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  &::before {
    position: fixed;
    content: " ";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(6, 20, 32, 1) 0%,
      rgba(9, 28, 45, 1) 24%,
      rgba(13, 41, 68, 1) 35%
    );
    z-index: -1;
  }
}


.products__canvas {
  margin: 32px 0;
}


.product-category__particles {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.products__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 16px;

  @media only screen and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 575px) {
    grid-template-columns: 1fr;
  }
}

.products__grid-link {
  position: relative;
  width: 100%;
  padding-bottom: 64px;
  text-align: center;
}

.products__grid-link:hover {
  .products__image {
    transform: scale(0.9);
  }
  .products__image-overlay::after {
    transform: translateY(0);
  }
  .products__link-text {
    // 16px is half of current positon of text
    bottom: calc(50% + 16px);
    color: $white;
    transform: translateX(calc(-50%)) scale(1.4);
  }
}

.products__frame {
  height: 100%;
  width: 100%;
  @media only screen and (max-width: 575px) {
    width: 80%;
    margin: 0 auto;
  }
  border: 8px solid $blue-lighter;
  box-shadow: 0 0 0 $white;
  transition: box-shadow 250ms;
  z-index: 1;
}
.products__image-wrap {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 2;
  text-align: center;
}

.products__image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 3;

  &::after {
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    width: 101%;
    height: 101%;
    background: rgba(#000, 0.5);
    transform: translateY(100%);
    transition: transform 350ms ease-in-out;
  }
}

.products__image {
  pointer-events: none;
  width: 90%;
  transform-origin: center;
  transition: transform 350ms;
  transform: scale(1.25) translateY(-8px);
}

.products__link-text {
  position: absolute;
  bottom: 24px;
  left: 50%;
  z-index: 5;
  transform: translateX(-50%);

  font-weight: bold;
  color: $white;
  font-size: 18px;
  transform-origin: center;
  transition: bottom 350ms, color 350ms, transform 350ms;
}
