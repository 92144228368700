.consulting__about__certificate {
  filter: drop-shadow(0 2px 2px black);
  width: 100%;
  margin-bottom: 3rem;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.consulting__about__text {
  text-align: left;
  padding: 5px;
  //margin-bottom: 3rem;
}

.consulting__about__me {
  text-align: left;
  padding: 5px;
  margin-bottom: 3rem;
}

.consulting__about__image {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  background: url(../../../assets/images/consulting/consulting-2.jpg);
  background-position: center;
  background-size: cover;
}

.consulting__about__profilePicture {
  width: 100%;
  display: flex;
}
