$height: 100px;

.prod-logo__wrap {
  position: relative;
  overflow: hidden;
  transition: opacity 1200ms;
}

.prod-logo__slider {
  display: flex;
  height: $height;
  width: fit-content;
  max-width: 100vw;
  // animation-name: logoStripAnim;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-direction: normal;
  // animation-duration: 20s;
}

.prod-logo__slide {
  display: flex;
  min-height: $height;
  height: $height;
  width: auto;
}

.prod-logo__slide-img {
  height: $height;
  min-height: $height;
  object-fit: contain;
}

@keyframes logoStripAnim {
  100% {
    transform: translateX(-66.6666%);
    // 2 thirds reset
    // this is logoImages from props are spread 3 times
  }
}

@keyframes logoStripAnimShort {
  100% {
    transform: translateX(-40%);
  }
}
