.commercial-slider__container {
  display: block;
  position: relative;
  width: 100%;
}

.commercial-slider__landscape {
  padding-bottom: calc(100% / 16 * 9);
}
.commercial-slider__portrait {
  padding-bottom: calc(100% / 2 * 3);
}

.commercial-slider__wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.commercial-slider__slider {
  display: flex;
  height: 100%;
}

.commercial-slider__slide {
  display: block;
  text-align: center;
  height: 100%;
  width: 100%;
}

.commercial-slider__slide-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
