.footer {
  width: 100vw;
  background-color: rgba($dark, 0.9);
  min-height: 45px;
  position: absolute;
}

.footer__content {
  padding-top: 15px;
  padding-bottom: 12px;
  color: $white;
}

.footer__links {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  list-style: none;
  a {
    color: rgba($white, 0.8);
    &:hover {
      color: rgba($white, 1);
    }
  }
}

.footer__links-group {
  display: flex;
}
.footer__links-group--left {
  a {
    margin-right: 24px;
  }
}
.footer__links-group--right {
  a {
    margin-left: 24px;
  }
}

.footer__copyrights {
  //margin-top: 12px;
  //width: 100%;
  text-align: center;
}

.footer__copyrights--mobile {
  display: none;
}

.footer__tnc-btn {
  background: transparent;
  text-align: left;
  border: none;
  color: rgba($white, 0.8);
  padding: 0;
  margin: 0;
  margin-right: 24px;
  &:focus,
  :active {
    outline: none;
  }
}

.footer__tnc-btn:hover {
  color: $white;
  text-decoration: underline;
  cursor: pointer;
}

.footer-terms-and-cond-button:active {
  background: transparent;
  border: none !important;
}

@media only screen and (max-width: 991px) {
  .footer {
    min-height: 144px;
  }
  .footer__content {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .footer__links-group {
    flex-direction: column;
  }
  .footer__links-group--right {
    align-items: flex-end;
  }
  .footer__copyrights--mobile {
    margin-top: 24px;
    display: inline-block;
  }
  .footer__copyrights {
    display: none;
  }
}
