// Override Default Fonts
$font-family-base: "Ubuntu";

$white: #ececec;
$grey: #a4a4a5;
$dark: #404041;
$blue-lighter: #37afe2;
$blue-deeper: #1f648c;
$yellow: #f0b929;

$image-overlay: linear-gradient(rgba($white, 0.7), rgba($white, 0.7));
