.product-category__wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  &::before {
    position: fixed;
    content: " ";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(6, 20, 32, 1) 0%,
      rgba(9, 28, 45, 1) 24%,
      rgba(13, 41, 68, 1) 35%
    );
    z-index: -1;
  }
}
.product-category__particles {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.product-category__image-wrapper {
  text-align: right;
}

.product-category__title {
  border-bottom: solid $blue-lighter 3px;
  font-weight: bold;
  font-size: 34px;
  color: $white;
  width: fit-content;
  margin: 3rem auto;
  position: relative;
}

.product-category__label {
  font-size: 16px;
  color: $white;
}

.product-category__animation {
  position: fixed;
  z-index: 0;
}

.product-category__polygon {
  pointer-events: none;
  position: fixed;
  top: 20%;
  right: 20px;
  width: 50%;
}

.product-category__input {
  background-color: rgba(black, 0.55);
  border: 1px solid $blue-deeper;
  color: $white;
  &:focus {
    background-color: rgba(black, 0.55);
    color: $white;
  }
}

.product-category__phone-input .PhoneInputInput {
  background-color: rgba(black, 0.55);
  border: 1px solid $blue-deeper;
  color: $white;
  &:focus {
    background-color: rgba(black, 0.55);
    color: $white;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

// Chrome always turns input field background color to white when you use autofill on it. This cannot be turned off, only delayed. So we delay it...
.product-category__input:-webkit-autofill,
.product-category__input:-webkit-autofill:hover,
.product-category__input:-webkit-autofill:focus,
.product-category__input:-webkit-autofill:active,
.PhoneInputInput:-webkit-autofill,
.PhoneInputInput:-webkit-autofill:hover,
.PhoneInputInput:-webkit-autofill:focus,
.PhoneInputInput:-webkit-autofill:active {
  transition-delay: 9999s;
  transition-property: background-color, color;
}

.product-category__textarea {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 0.25rem;

  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

.product-category__text {
  background-color: rgba(black, 0.55);
  color: $white;
  text-align: center;
  padding: 5px 5px 5px 5px;
}
