.gallery-tiles {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media only screen and (max-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: 575px) {
    grid-template-columns: repeat(2, 1fr);
  }
  grid-template-rows: auto;
  grid-gap: 15px;
  margin: 15px 0;
}

.gallery-tile {
  position: relative;
  width: 100%;
  padding-bottom: calc(100% / 11 * 15);
  cursor: pointer;
  overflow: hidden;
  border: 1px solid $grey;

  &:hover img {
    transform: translateY(-50%) scale(1.1);
  }
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: fit-content;
    object-fit: contain;
    transition: transform 350ms ease;
  }
}

.reference-gallery-tile {
  position: relative;
  width: 100%;
  padding-bottom: calc(100% / 16 * 9);
  cursor: pointer;
  overflow: hidden;
  border: 1px solid $grey;

  &:hover img {
    transform: translateY(-50%) scale(1.1);
  }
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: fit-content;
    object-fit: contain;
    transition: transform 350ms ease;
  }
}

.gallery-tile__btn {
  position: absolute;
  top: 0;
  right: 0;
}

.gallery__lightbox {
  height: 100%;
  .modal-dialog {
    width: calc(100vw - 20px);
    max-width: 1200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0;
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: transparent;
    background-clip: padding-box;
    border: none;
    border-radius: 0;
    outline: 0;
    height: 100%;
  }
}

.gallery__lightbox-img-wrap {
  width: 100%;
  text-align: center;
}
.gallery__lightbox-img {
  //width: 100%;
  max-height: 90vh;
  max-width: 90vw;
}

.gallery__lightbox-btn {
  background-color: transparent;
  color: $dark;
  transition: color 250ms;
  filter: drop-shadow(0 0 3px $white);
  border: none;
  outline: none;
  &:active,
  &:focus {
    outline: none;
  }
  &:active {
    color: $blue-lighter;
  }
  &:hover {
    color: $blue-deeper;
  }
}

.gallery__lightbox-close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 32px;
  line-height: 32px;
  padding: 8px;
}

.gallery__lightbox-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 70px;
}

.gallery__lightbox-previous {
  left: 0;
}

.gallery__lightbox-next {
  right: 0;
}
