* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  overflow-x: hidden;
  position: relative;
  min-height: 100vh;
  width: 100vw;

  .app__transition-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    padding-top: 80px; // header height 80px
  }

  .app__content {
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
    // header height 80px
    // footer height 85px
    min-height: calc(100vh - 45px - 85px);
    @media only screen and (max-width: 991px) {
      // footer height 144px
      min-height: calc(100vh - 80px - 144px);
    }
  }
}

div[style*="transform: translateY(-100vh); opacity: 0;"] {
  display: none;
}
