.modal-dialog {
  width: 90vw;
  max-width: 800px;
}

.commercial-float-wrap {
  display: inline;
  float: right;
  max-width: 250px;
  width: 40%;
  margin-left: 15px;
  margin-bottom: 15px;
}

.page__title {
  color: $white;
  font-weight: bold;
  text-shadow: 2px 2px 3px $dark, -2px 2px 3px $dark, 2px -2px 3px $dark,
    -2px -2px 3px $dark;
  text-align: center;
  font-size: 70px;
  @media only screen and (max-width: 991px) {
    font-size: 55px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 40px;
    text-shadow: 1px 1px 3px $dark, -1px 1px 3px $dark, 1px -1px 3px $dark,
      -1px -1px 3px $dark;
  }
  @media only screen and (max-width: 575px) {
    font-size: 36px;
  }
}

textarea {
  resize: none;
}
