.contact__wrap {
  position: relative;
  min-height: 100%;
  overflow: hidden;
  background: rgb(33, 116, 160);
  background: linear-gradient(
    117deg,
    rgba(33, 116, 160, 1) 0%,
    rgba(52, 166, 217, 1) 39%,
    rgba(55, 175, 227, 1) 73%
  );
}

.contact-h1 {
  font-weight: bold;
  font-size: 3rem;
  color: #ececec;
}

.contact-input-row {
  display: grid;
  grid-template-columns: 30% 70%;
  width: 100%;

  @media only screen and (max-width: 575px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
}

.contact-input-centered {
  text-align: center;
}

.contact-textarea {
  padding: 0.375rem 0.75rem;
  background-color: #ececec;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  &:focus {
    outline: none;
  }
}

.contact-label {
  font-size: 16px;
  color: #ececec;
}
.contact__logo-div {
  position: relative;
  display: flex;
  justify-content: center;
  perspective: 1000px;
  perspective-origin: 50% 50%;
}

.job__keyboard {
  position: absolute;
  display: none;
  width: 400px;
  height: 250px;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  grid-gap: 10px;
  top: 50%;
  transform: rotateX(48.8deg) rotateY(8deg) rotateZ(13deg);
}
.job__keyboard--left {
  @media only screen and (min-width: 769px) and (max-width: 991px) {
    display: grid;
    transform: rotateX(48.8deg) rotateY(8deg) rotateZ(13deg) scale(0.5);
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    display: grid;
    transform: rotateX(48.8deg) rotateY(8deg) rotateZ(13deg) scale(0.82);
  }
  @media only screen and (min-width: 1201px) {
    display: grid;
  }
}
.job__keyboard--bottom {
  bottom: 0;
  top: initial;
  left: 50%;
  transform: translate(-50%) rotateX(48.8deg) rotateY(8deg) rotateZ(13deg);

  @media only screen and (max-width: 575px) {
    transform: translate(-50%) rotateX(48.8deg) rotateY(8deg) rotateZ(13deg)
      scale(0.7);
  }
  @media only screen and (max-width: 768px) {
    display: grid;
  }
}

.contact__container {
  position: relative;
  @media only screen and (max-width: 768px) {
    padding-bottom: 270px;
  }
}

.job__keyboard-btn {
  background-color: rgba(white, 0.8);
  border-radius: 10px;
}

.job__keyboard-btn--enter {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: orange;
  grid-column: 3 / 6;
  grid-row: 2;
  color: $white;
  text-shadow: 1px 1px 1px $dark, -1px -1px 1px $dark, -1px 1px 1px $dark,
    1px -1px 1px $dark;
  font-size: 30px;
  line-height: 0.4;
  font-weight: bold;
  animation: tipkanje 7s ease-in-out infinite;
  @keyframes tipkanje {
    20% {
      transform: none;
    }
    22% {
      transform: translate(5px, 10px) scale(0.9);
    }
    24% {
      transform: none;
    }
    26% {
      transform: translate(5px, 10px) scale(0.9);
    }
    28% {
      transform: none;
    }
  }
}
.job__keyboard-btn--wider {
  grid-column: 2/ 5;
  grid-row: 4;
}
.job__keyboard-btn--wide {
  grid-column: 4/ 6;
  grid-row: 1;
}
.job__keyboard-btn--tall {
  grid-column: 1;
  grid-row: 2 / 4;
}

header {
  grid-area: header;
}

.contact__logo-wrapper {
  width: 50%;
  @media only screen and (max-width: 768px) {
    width: 60%;
    margin-bottom: 70px;
  }
}

.contact__logo {
  filter: drop-shadow(0 2px 2px black);
  width: 100%;
}

.contact__form {
  margin-left: 15px;
  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }
}

.contact__captcha div div {
  //text-align: center;
  width: 100% !important;
}

.contact__map {
  height: calc(50vh);
}

.PhoneInputInput {
  // hard coded class override
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ececec;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-left: 0.5em;
  &:focus {
    outline: none;
  }
}

.contact__info {
  font-size: 16px;
  color: #ececec;
  text-align: center;
}
