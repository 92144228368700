.header {
  position: absolute;
  background-color: $dark;
  width: 100vw;
  z-index: 1002;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  .container,
  .row,
  .col {
    height: 100%;
  }
}

.header__content {
  position: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo-wrap {
  position: relative;
  min-width: 230px;
  @media only screen and (max-width: 700px) {
    min-width: 175px;
  }
  &::before {
    position: absolute;
    content: " ";
    top: 50%;
    left: 30px; // half of the logo height/width (it's a circle)
    @media only screen and (max-width: 700px) {
      left: 23px;
      width: 70px;
      height: 70px;
    }
    transform: translate(-50%, -50%);
    width: 85px;
    height: 85px;
    background: radial-gradient($blue-lighter, transparent 75%);
    z-index: -1;
    opacity: 0.5;
    transition: opacity 300ms;
  }
}
.header__logo-link--active .header__logo-wrap {
  &::before {
    opacity: 1;
  }
}

.header__logo {
  height: 60px;
  @media only screen and (max-width: 700px) {
    height: 46px;
  }
}
.header__logo-text {
  height: 50px;
  margin-left: 24px;

  @media only screen and (max-width: 1199px) {
    height: 40px;
  }
  @media only screen and (max-width: 700px) {
    height: 35px;
  }
}

.header__spacer {
  flex-grow: 1;
}

.header__nav {
  flex-grow: 1;
}

.header__nav-ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
  height: 100%;
}
.nav-link__arrow {
  display: none;
}

.nav-link {
  margin-left: 24px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  transition: color 300ms;
  color: rgba($white, 0.7);
  &:hover {
    color: rgba($white, 1);
  }
}
.nav-link__content {
  position: relative;
  &::after {
    position: absolute;
    content: "";
    top: calc(100% + 2px);
    left: 0;
    right: 0;
    width: 100%;
    height: 3px;
    background-color: $blue-lighter;
    opacity: 0;
    transition: opacity 300ms;
  }
}
.nav-link--active {
  color: $white;
  .nav-link__content::after {
    opacity: 1;
  }
}

.header__language-picker {
  display: flex;
}

.header__admin-panel-link {
  padding: 0;
  a {
    text-decoration: none;
  }
}

.header__nav-hamburger {
  display: flex;
  @media only screen and (min-width: 1200px) {
    display: none;
  }
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  height: 44px;
  width: 60px;
  min-height: 44px;
  min-width: 60px;
  padding: 10px;
  margin-right: -10px;
  &:hover {
    .header__hamburger-line {
      background-color: $blue-lighter;
    }
  }
}

.header__hamburger-line {
  width: 100%;
  height: 4px;
  background-color: $grey;
  transition: transform 400ms, background-color 150ms;
}

.header__nav-hamburger--on {
  .header__hamburger-line--1 {
    transform: rotate(40deg) translate(4px, 10px);
  }
  .header__hamburger-line--2 {
    transform: scale(0);
  }
  .header__hamburger-line--3 {
    transform: rotate(-40deg) translate(4px, -10px);
  }
}

@media only screen and (max-width: 1199px) {
  .header__nav {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100vw;
    height: calc(100vh - 80px); // header height 80px
    transform: translateX(-100vw);
    transition: transform 400ms;
  }

  .header__nav--on {
    transform: translateX(0);
  }
  .header__nav-ul {
    background-color: rgba($dark, 0.9);
    display: block;
    height: auto;
    min-height: 100%;
  }
  .header__nav-li {
    width: 100%;
  }
  .nav-link {
    width: 100%;
    margin: 0;
    padding: 15px;
    font-size: 24px;
    text-align: center;
    &::after {
      display: none;
    }
  }
  .nav-link__content {
    position: relative;
  }
  .nav-link__arrow {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);
    width: 0;
    border: 15px solid transparent;
    border-left-color: $blue-lighter;
    opacity: 0;
    transition: opacity 200ms;
  }
  .nav-link--active {
    .nav-link__arrow {
      opacity: 1;
    }
  }
}
