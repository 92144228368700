.company__wrapper {
  width: 100%;
  height: 100%;

  &::before {
    position: fixed;
    content: " ";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $image-overlay, url(../../assets/images/about/about.jpg);
    background-position: center;
    background-size: cover;
    z-index: -1;
  }
}

.company__content {
  padding: 15px;
}

.company__title {
  text-align: center;
  font-weight: bold;
  margin-bottom: 30px;
}

.company__logo-wrapper {
  display: inline;
  float: right;
  max-width: 250px;
  width: 40%;
  margin-left: 15px;
  margin-bottom: 15px;
}
.company__logo {
  width: 100%;
  filter: drop-shadow(0 2px 2px black);
}
