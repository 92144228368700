.home-page {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;
}

.home-page__background-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../assets/images/svg/home-page-background.svg");
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.home-page__content {
  display: flex;
  flex-grow: 1;
  padding: 15px;
}

.home-page__webshop,
.home-page__consulting {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 50%;

  cursor: pointer;
  &:hover {
    .home-page__title {
      opacity: 1;
    }
  }
}

.home-page__title {
  font-size: 70px;
  font-weight: 900;
  font-family: "Ubuntu Bold";
  text-transform: uppercase;
  text-align: center;
  letter-spacing: -3px;
  margin: 0;
  opacity: 0.5;
  transition: opacity 300ms;
  @media only screen and (max-width: 991px) {
    font-size: 55px;
    opacity: 1;
  }
  @media only screen and (max-width: 768px) {
    font-size: 40px;
    letter-spacing: 0;
  }
  @media only screen and (max-width: 575px) {
    font-size: 24px;
  }
}

.home-page__title--webshop {
  color: $dark;
}

.home-page__title--consulting {
  color: $white;
  z-index: 3;
}

.home-page__image-container {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 2;
}

///////////////////////////
// Sign change animation
///////////////////////////

$signs-width: -251.8px;
$animation-steps: 12;
#signs-array {
  // transform: translateX(calc($signs-width * 1));
  animation: sign-slider 26s infinite ease;
}

@keyframes sign-slider {
  @for $i from 0 to $animation-steps {
    #{$i/($animation-steps + 1) * 100%} {
      transform: translateX($signs-width * ($i + 1));
    }
    #{($i + 0.3)/($animation-steps + 1) * 100%} {
      transform: translateX($signs-width * ($i + 1));
    }
  }
  100% {
    transform: translateX($signs-width * ($animation-steps + 1));
  }
}

///////////////////////////
// Remote signal animation
///////////////////////////
.home-page__webshop-img--animated {
  @for $i from 0 to 6 {
    .remote-signal-#{$i} {
      animation: remote-signal-flicker 2.4s #{$i * 0.2}s infinite ease;
    }
    #{$i/($animation-steps + 1) * 100%} {
      transform: translateX($signs-width * ($i + 1));
    }
    #{($i + 0.3)/($animation-steps + 1) * 100%} {
      transform: translateX($signs-width * ($i + 1));
    }
  }

  @keyframes remote-signal-flicker {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0.9;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}
