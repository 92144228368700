.consulting__container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  overflow: hidden;
}

.consulting__content {
  width: 100%;
  height: 100%;

  &::before {
    position: fixed;
    content: " ";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba($white, 0.8), rgba($white, 0.8)),
      url(../../../assets/images/consulting/consulting-2.jpg);
    background-position: center;
    background-size: cover;
    z-index: -1;
  }
}

.consulting__grid {
  display: grid;
  grid-gap: 16px;
  padding-bottom: 100px;
}

.consulting__link {
  position: relative;
  text-align: center;
  color: $white;
  h2 {
    font-size: 32px;
    font-weight: bold;
    text-shadow: 1px 1px 2px $dark, -1px 1px 2px $dark, 1px -1px 2px $dark,
      -1px -1px 2px $dark;
  }
  display: block;
  padding: 16px 8px;
  z-index: 2;

  &::after {
    z-index: -1;
    position: absolute;
    content: " ";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $dark;
    transition: background-color 250ms, opacity 250ms;
    opacity: 0.65;
  }

  &:hover {
    text-decoration: none;
    color: $white;
    &::after {
      background-color: $blue-lighter;
      opacity: 1;
    }
  }
}
.consulting__background__wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: -1;
}

.consulting__background__slider {
  display: flex;
  height: 100%;
}

.consulting__background__slide {
  display: block;
  text-align: center;
  height: 100%;
}

.consulting__background__slide-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
