.consulting_safety__wrapper {
  width: 100%;
  height: 100%;

  &::before {
    position: fixed;
    content: " ";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba($white, 0.8), rgba($white, 0.8)),
      url(../../../assets/images/consulting/consulting-4.jpg);
    background-position: center;
    background-size: cover;
    z-index: -1;
  }
}

.consulting_safety__title {
  text-align: center;
  font-weight: bold;
  margin-bottom: 30px;
}
