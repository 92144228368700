.references__wrapper {
  width: 100%;
  height: 100%;

  &::before {
    position: fixed;
    content: " ";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $image-overlay,
      url(../../../assets/images//consulting/consulting-5.jpg);
    background-position: center;
    background-size: cover;
    z-index: -1;
  }
}
.references__content hr:last-of-type {
  display: none;
}

.references__image {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  background: url(../../../assets/images/consulting/consulting-5.jpg);
  background-position: center;
  background-size: cover;
}

.references__img-wrapper {
  display: inline;
  float: left;
  width: 280px;
  margin: 0 20px 20px 0;
  @media only screen and (max-width: 575px) {
    width: 200px;
  }
  @media only screen and (max-width: 400px) {
    width: 100%;
    margin-bottom: 20px;
  }
}
.references__delete-btn {
  display: inline-block;
}
