// Import custom local fonts
@import "./fonts";

// If you want to override variables do it here
@import "./variables";

// Import Bootstrap styles
@import "~bootstrap/scss/bootstrap";

// If you want to add something do it here
@import "./layout/layout";
@import "./layout/header";
@import "./layout/footer";
@import "./common";
@import "./custom";

// components
@import "./components/nav";
@import "./components/gallery";
@import "./components/commercial-slider";
@import "./components/editable-text";

// pages
@import "./pages/home";
@import "./pages/products";
@import "./pages/company";
@import "./pages/contact";
@import "./pages/auth";
@import "./pages/product-category";
@import "./pages/products-logos";

@import "./pages/consulting/consulting";
@import "./pages/consulting/consulting-about";
@import "./pages/consulting/consulting-formation";
@import "./pages/consulting/consulting-safety";
@import "./pages/consulting/consulting-references";
